//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// importing components
import Navbar from '~/components/Skeleton/Navbar.vue';
import analyticEvents from '~/services/analyticEvents';
// import 'animate.css';
import { ROUTES } from '~/constants/pageRoutes';
import { isBotUserAgent } from '~/services/utilityFunctions.js';
const MobileSideBar = () => import('~/components/Navbar/Pwa/MobileSideBar.vue');
const TabletSidebar = () => import('~/components/Navbar/Tablet/TabletSidebar.vue');
const ExamDetailsFillingModal = () => import('~/components/Auth/ExamDetailsFillingModal.vue');
const ModalContainer = () => import('~/components/ReusableComponents/ModalContainer.vue');
const AuthModal = () => import('~/components/Auth/AuthModal.vue');
// const AppWideFeatureInfoBanner = () =>
//     import('~/components/ReusableComponents/AppWideFeatureInfoBanner.vue');

export default {
    name: 'Default',
    components: {
        Navbar,
        MobileSideBar,
        TabletSidebar,
        ExamDetailsFillingModal,
        ModalContainer,
        AuthModal
        // AppWideFeatureInfoBanner
    },
    computed: {
        ...mapGetters({
            fetchingUser: 'auth/fetchingUser',
            sideBarOpen: 'UIElementsState/getSideBarStatus',
            profileCompletionLevel: 'auth/profileCompletionLevel',
            showAcquisitionAuthModal: 'auth/showAcquisitionAuthModal',
            showInstructions: 'testSets/showInstructions'
        }),
        notInExcludedPages() {
            return (
                ![ROUTES.PRICING, ROUTES.LOGIN].includes(this.$route.path) && !this.showInstructions
            );
        },
        bannerExcludedPage() {
            const isExcluded = [ROUTES.FREE_STARTER_KIT, ROUTES.FREEBIES].includes(
                this.$route.path
            );
            return isExcluded;
        },
        isNonDismissableModal() {
            if (process.client) {
                return Boolean(window.sessionStorage.getItem('acq_auth_shown'));
            }

            return false;
        }
    },
    watch: {
        showAcquisitionAuthModal: {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.redirection_modal,
                        data: {
                            modal_type: this.isNonDismissableModal
                                ? 'non_dismissable'
                                : 'dismissable'
                        }
                    });
                }
            }
        }
    },
    mounted() {
        this.attachDocumentEvents();
        console.log('user agent', window.navigator.userAgent);

        // Importing it later on asynchronously so as to avoid it in initial bundle
        setTimeout(() => {
            import('animate.css');
        }, 1000);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.clickListener);
    },
    methods: {
        isBotUserAgent,
        attachDocumentEvents() {
            document.addEventListener('click', this.clickListener);
        },
        clickListener(event) {
            if (
                this.sideBarOpen &&
                !event.target.closest('#side-bar-menu') &&
                !event.target.closest('#side-bar-hamburger')
            ) {
                this.$store.commit('UIElementsState/setSideBarStatus', false);
            }
        },
        acquisitionSignupHandler() {
            this.$store.commit('auth/clearAcquisitionAuthTimer');
            this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);
            window.location.reload();
        },
        closeAcquisitionAuthModal() {
            this.$store.commit('auth/toggleTimerBasedAcqAuthModal', false);

            // Fire analytic event
            const trackingEvent = {
                event: analyticEvents.signup_acqmodal_dismiss
            };
            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};
