export const EXAM_FEES = `<h1><strong>GMAT Exam Fees 2024: What is the GMAT Exam Fee in India?</strong></h1>
<p>The GMAT, a highly regarded computer-adaptive entrance test, is crucial for aspiring business school students worldwide. This 2-hour, 15-minutes exam comprises three sections: Integrated Reasoning, Quantitative Reasoning, Verbal Reasoning, and Data Insights (new addition). The <a href="https://prep.yocket.com/gmat" target="_blank" rel="noopener">GMAT</a> is not just a test but an investment in your future business career. In India, the base fee for the GMAT is USD 275 (INR 23,091), but many candidates overlook the additional costs like rescheduling and cancellation fees. Being aware of these can save you both time and money as you plan your journey to business school.
</p>

<p><strong>Key Highlights:</strong></p>
<ul>
<li>The GMAT registration fee in India is USD 275 (INR 23,091) for the in-person test and USD 300 (INR 25,190) for the online test.</li> 
<li>The GMAT exam fee is non-refundable once paid.</li>
<li>The GMAT cancellation fee is USD 165 (INR 13,860) if you're cancelling more than 2 months before the exam.</li> 
<li>The GMAT rescheduling fee is USD 50 (INR 4,200) if the test is rescheduled more than 60 days in advance.</li>
<li>The accepted methods of payment for booking the GMAT slot are credit/debit cards (VISA, Mastercard, American Express, and Discover Network Cards), PayPal, and wire transfers.</li>
</ul>
<p>
This article provides a comprehensive overview of all the costs associated with the GMAT exam, helping you make informed decisions and budget accordingly.</p>
<h2 id="what-is-the-gmat-exam-fee-in-india-for-2024">What is the GMAT Exam Fee in India for 2024?</h2>
<p>In India, the GMAT exam fee is <strong>USD 275 (INR 23,091)</strong> for tests taken at a test centre, while the fee for an online test is <strong>USD 300 (INR 25,190)</strong>. Here's a breakdown of the additional fees & costs associated with the GMAT exam fees:</p>
<div style="overflow: auto;">
<table>
  <thead>
    <tr>
      <th><p><strong>GMAT Exam Fees in India</strong></p></th>
      <th><p><strong>Test Centre</strong></p></th>
      <th><p><strong>Online</strong></p></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><p>GMAT Exam Fees</p></td>
      <td><p>USD 275 (INR 23,091)</p></td>
      <td><p>USD 300 (INR 25,190)</p></td>
    </tr>
    <tr>
      <td><p>Enhanced Score Report (purchased at the time of registration)</p></td>
      <td><p>USD 30 (INR 2,500)</p></td>
      <td><p>Not Available</p></td>
    </tr>
    <tr>
      <td><p>Additional Score Report</p></td>
      <td><p>USD 35 (INR 2,920) each</p></td>
      <td><p>USD 35 (INR 2,920) each</p></td>
    </tr>
    <tr>
      <td><p>Cancel score (via mba.com My Account page after the exam)</p></td>
      <td><p>USD 25 (INR 2,080)</p></td>
      <td><p>Not Applicable</p></td>
    </tr>
    <tr>
      <td><p>Reinstate score</p></td>
      <td><p>USD 50 (INR 4,170)</p></td>
      <td><p>Not Applicable</p></td>
    </tr>
    <tr>
      <td><p>AWA Essay Rescoring</p></td>
      <td><p>USD 45 (INR 3,750)</p></td>
      <td><p>USD 45 (INR 3,750)</p></td>
    </tr>
    <tr>
      <td><p>Rescheduling fees for more than 60 days before the appointment</p></td>
      <td><p>USD 55 (INR 4,590)</p></td>
      <td><p>USD 60 (INR 5,010)</p></td>
    </tr>
    <tr>
      <td><p>Rescheduling fees for 15 - 60 days before the appointment</p></td>
      <td><p>USD 110 (INR 9,180)</p></td>
      <td><p>USD 120 (INR 10,020)</p></td>
    </tr>
    <tr>
      <td><p>Rescheduling fees for 14 days or less before the appointment</p></td>
      <td><p>USD 165 (INR 13,780)</p></td>
      <td><p>USD 180 (INR 15,030)</p></td>
    </tr>
    <tr>
      <td><p>Cancellation fees for more than 60 days before the appointment</p></td>
      <td><p>USD 110 (INR 9,180) refund</p></td>
      <td><p>USD 120 (INR 10,020) refund</p></td>
    </tr>
    <tr>
      <td><p>Cancellation fees for 15 - 60 days before the appointment</p></td>
      <td><p>USD 80 (INR 6,680) refund</p></td>
      <td><p>USD 90 (INR 7,510) refund</p></td>
    </tr>
    <tr>
      <td><p>Cancellation fees for 14 days or less before the appointment</p></td>
      <td><p>USD 55 (INR 4,590) refund</p></td>
      <td><p>USD 60 (INR 5,010) refund</p></td>
    </tr>
  </tbody>
</table>
</div>
<p class="suggested-blog-block-itr-1"><strong>Note:</strong> Effective 31st March 2023, as per government budget guidelines, 18% GST will be additionally applicable.</p>
<h2 id="gmat-exam-cancellation-fees">GMAT Exam Cancellation Fees</h2>
<p>GMAC has updated the GMAT cancellation fee structure to a three-tier system which is based on when you cancel the GMAT.
If you cancel your GMAT more than 60 days before the appointment, a refund of $110 to $120 is issued; if cancelled 15-60 days prior, the refund ranges from $80 to $90; and if cancelled 1-14 days before, the refund is between $55 and $60. The GMAT has specified exam cancellation policies, including partial refunds if cancelled well in advance and no refund on last-minute cancellations. The below table gives an overview of it:</p>
<div style="overflow: auto;">
<table>
  <thead>
    <tr>
      <th><p><strong>GMAT Cancellation Time Frame</strong></p></th>
      <th><p><strong>Refund Amount (Test Centre)</strong></p></th>
      <th><p><strong>Refund Amount (Online)</strong></p></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><p>More than 60 days before the appointment</p></td>
      <td><p>USD 110 (INR 9,160)</p></td>
      <td><p>USD 120 (INR 10,010)</p></td>
    </tr>
    <tr>
      <td><p>15 - 60 days before an appointment</p></td>
      <td><p>USD 80 (INR 6,660)</p></td>
      <td><p>USD 90 (INR 7,500)</p></td>
    </tr>
    <tr>
      <td><p>14 days and less before an appointment</p></td>
      <td><p>USD 55 (INR 4,580)</p></td>
      <td><p>USD 60 (INR 5,010)</p></td>
    </tr>
  </tbody>
</table>
</div>
<p class="suggested-blog-block-itr-1"><strong>Note:</strong> The GMAT test appointment cannot be cancelled or rescheduled within 24 hours of the scheduled date and time.</p>
<h2 id="gmat-exam-rescheduling-fees">GMAT Exam Rescheduling Fees</h2>
<p>You can reschedule your GMAT exam by paying a rescheduling fee and by following a set of policies. It is best to be well aware of these policies before planning your test date. This table gives an overview of the current GMAT rescheduling fees and policies for making a decision.</p>
<div style="overflow: auto;">
<table>
  <thead>
    <tr>
      <th><p><strong>Reschedule Time Frame</strong></p></th>
      <th><p><strong>GMAT Rescheduling Fees for GMAT Exam at a Test Centre</strong></p></th>
      <th><p><strong>GMAT Rescheduling Fees for Online GMAT Exam</strong></p></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td><p>More than 60 days before the appointment</p></td>
      <td><p>USD 55 (INR 4,580)</p></td>
      <td><p>USD 60 (INR 5,010)</p></td>
    </tr>
    <tr>
      <td><p>15 - 60 days before an appointment</p></td>
      <td><p>USD 110 (INR 9,160)</p></td>
      <td><p>USD 120 (INR 10,010)</p></td>
    </tr>
    <tr>
      <td><p>14 days and less before an appointment</p></td>
      <td><p>USD 165 (INR 13,750)</p></td>
      <td><p>USD 180 (INR 15,010)</p></td>
    </tr>
  </tbody>
</table>
</div>
<p><img class="article-image" alt="The USA, India, and China are the top three countries with the highest number of GMAT test-takers, showcasing its global reach and importance in business education." width="519" height="519" data-src="${process.env.CDN_ENDPOINT}/images/articles/gmat-facts-2.webp" /></p>
<h2 id="gmat-exam-fee-reschedule-policy">GMAT Exam Fee Reschedule Policy</h2>
<p>Candidates should know how to reschedule the GMAT exam if their exam date changes. The following points outline a description of the GMAT rescheduling policy to ensure knowledge of what is going down in making decisions concerning the scheduling of exams:</p>
<ul>
<li>You can cancel or reschedule your GMAT Exam (GMAT Focus Edition) appointment up to 24 hours before the scheduled appointment.</li>
<li>The fees for rescheduling, cancellations, and refunds depend on when you make the request and the local laws in your area.</li>
<li>In case you do not turn up for your GMAT exam, you will need to wait another 24 hours to schedule a new appointment. An appointment less than 24 hours away cannot be cancelled or rescheduled.</li>
<li>If you do not cancel or reschedule your exam more than 24 hours in advance, you will lose the entire exam fee.</li></ul>
<p class="suggested-blog-block-itr-0"><strong>Suggested:</strong> <a href="https://yocket.com/blog/how-to-prepare-for-gmat" target="_blank" rel="noopener">How to Prepare for the GMAT Exam?</a></p>
<h2 id="gmat-exam-fee-waiver">GMAT Exam Fee Waiver</h2>
<p>The GMAT fee waiver is a great opportunity for students facing financial difficulties, offering relief from the exam fees. However, it’s important to note that the process is not as simple as applying directly to GMAC. Instead, business schools play a crucial role in determining eligibility and distributing the waivers.</p>
<p>Now, let’s understand the GMAT fee waiver eligibility and how to apply for the GMAT fee waiver: 
</p>
<h3>GMAT Fee Waiver Eligibility</h3>
<p>The GMAT fee waiver is a valuable option for students from financially underprivileged backgrounds, helping to reduce the financial burden of taking the exam. However, while anyone can apply for the waiver, GMAC doesn’t allow direct applications. Instead, it's the business schools that determine the eligibility and grant the fee waivers. Here's a quick look at how it works:</p>
<ul>
<li>GMAC allows business schools to decide who is eligible for a fee waiver.</li>
<li>Schools can request up to 10 fee waivers per year.</li>
<li>Each school sets its own criteria for financial need.</li>
<li>Applicants must prove their financial need to the school.</li>
<li>Either the candidate's undergraduate institution or MBA program can request the fee waiver on their behalf.</li>
<li>Candidates should check with the school’s financial aid department to confirm eligibility.</li>
<li>Schools decide both the eligibility and the amount of the fee waiver.</li>
<li>A financial need statement explaining why the candidate cannot afford the GMAT fee is required for the application.</li>
</ul>
<h3>How to Apply for GMAT Fee Waiver?</h3>
<p>Fee waivers need to be requested by a Director or similar position and are only available to programs that accept GMAT scores. The application process for GMAT fee waivers involves several steps, and it’s essential to follow the school’s guidelines to increase your chances of approval.</p> 
<p>Here's how to apply:</p>
<li><strong>Check with the School:</strong> Before applying for a fee waiver, reach out to the financial aid department of the business school to confirm if they offer GMAT fee waivers and understand their specific criteria.</li>
<li><strong>Submit a Financial Need Statement:</strong> You’ll need to provide a detailed financial need statement explaining your economic situation and why you cannot afford the GMAT exam fee.</li>
<li><strong>Request Through the School:</strong> As an applicant, you cannot directly request a fee waiver from GMAC. The request must be made by a Director or similar authority from the school or program you're applying to.</li>
<li><strong>Provide Required Documentation:</strong> Be prepared to submit any required documents such as proof of financial hardship, academic records, or other supporting materials that may help justify your request.</li>
<li><strong>Wait for School Approval:</strong> Once submitted, the school will review your application and determine whether to approve your fee waiver request. They will also decide the amount of the waiver if granted.</li>
<h2 id="accepted-modes-of-payment-for-booking-the-gmat-exam-slot">Accepted Modes of Payment for Booking the GMAT Exam Slot</h2>
<p>While GMAC accepts convenient payments from VISA, Mastercard, American Express, and Discover Network cards, please be aware that your appointment will be automatically cancelled if your chosen card's payment is declined. In such cases, you'll need to reschedule your test with a valid payment method.</p>
<p>For those who prefer alternative methods, cash, money orders, and cheques are also accepted. However, please ensure the following:</p>
<ul><li>Your payment is payable to Pearson VUE-GMAT in US dollars, drawn on a US bank, and covers the full amount, including any applicable taxes.</li>
<li>The cheque includes the current date, your signature, and the pre-printed bank name and location.</li>
<li>Your payment is drafted at least 10 days before your test date to allow for processing.</li></ul>
<h2 id="about-gmat-exam-2024">About GMAT Exam 2024</h2>
<p>The GMAT is known for its wide range of question types, making it a challenging test for business school applicants.</p>
<p>To understand this effectively, here are some crucial points to consider before you start your GMAT journey:</p>
<ul>
<li> <strong>Target score:</strong> For international students, understanding the <a href="https://prep.yocket.com/gmat/score-chart" target="_blank" rel="noopener">GMAT's scoring range</a> (205-805) is crucial before diving into research. Aim for a score that aligns with your target school's requirements.</li>
<li> <strong>Focus on core skills:</strong> The GMAT doesn't assess communication, social, leadership, or teamwork skills. It primarily focuses on quantitative, verbal, and data insights.</li>
<li> <strong>Schedule wisely:</strong> Plan your attempts strategically. You can take the GMAT up to 5 times per year, with a lifetime limit of 8 attempts.</li>
<li> <strong>Global reach:</strong> The GMAT is accepted by over 7,700 programs at 2,400 universities in 114 countries. Testing is available at more than 600 centres worldwide, offering global opportunities.</li>
<li> <strong>Fairness and inclusivity:</strong> International experts curate the test questions, incorporating multicultural examples to minimise bias towards English speakers or US-centric perspectives.</li>
</ul>
<h2 id="how-to-register-for-the-gmat-exam">How to Register for the GMAT Exam?</h2>
<p>Registering for the GMAT is a straightforward process, and you have two options for taking the exam: online or at a test centre. Here's a step-by-step guide for registering for the GMAT:</p>
<p><strong>Step 1: </strong> Create an account on MBA.com. This is the official website for the GMAT, and it's required for scheduling and managing your exam. Simply click “Register” and follow the prompts to create your account.</p>
<p><strong>Step 2: </strong> Decide whether you'd prefer to take the <a href="https://prep.yocket.com/gmat/gmat-online-exam" target="_blank" rel="noopener">GMAT online</a> or at a test centre.</p>
<p><strong>Step 3: </strong> Provide basic information like your name, contact details, and educational background.</p>
<p><strong>Step 4: </strong> Select your desired date and time from the available options.</p>
<p><strong>Step 5: </strong> Pay the registration fee. The standard fee for the GMAT at a centre is <strong>USD 275 or INR 23,091</strong>, and for the online GMAT, it's <strong>USD 300 or INR 25,190</strong> The payment can be made using credit or debit cards.</p>
<p><strong>Step 6: </strong> After registering, you'll receive a confirmation and access to study resources on MBA.com.</p>
<p class="suggested-blog-block-itr-0"><strong>Suggested:</strong> Find out <a href="https://prep.yocket.com/gmat/how-to-register" target="_blank" rel="noopener">how to register</a> for the GMAT exam 2024 with our step-by-step guide.</p>
<h2 id="from-the-desk-of-yocket">From the Desk of Yocket</h2>
<p>Understanding the various fees associated with the GMAT is crucial when planning your exam strategy. You need to be well-prepared not just academically but financially as well. As discussed, the exam costs go beyond the registration fee, with potential charges for rescheduling or cancellation. To support your study efforts, <a href="https://prep.yocket.com/" target="_blank" rel="noopener">Yocket Prep</a> offers customised resources to guide you through the exam, helping you optimise your preparation.</p>
<p>Taking the time to budget for these expenses will allow you to approach your GMAT preparation more strategically. For those seeking personalised advice and support, consider <a href="https://yocket.com/premium/" target="_blank" rel="noopener">Yocket Premium</a>, which provides guidance to maximise your chances of success. Make a choice your future self will thank you for, today.</p>
<h2 id="frequently-asked-questions-about-gmat-exam-fees-2024-in-india">Frequently Asked Questions about GMAT Exam Fees 2024 in India</h2>

<h3>1. What if I am unable to attempt the GMAT exam?</h3>
<p><strong>Ans.</strong> To avoid forfeiting your GMAT exam registration fee, it is recommended that you reschedule your attempt at your earliest convenience</p>
<h3>2. Will I be charged extra if I cancel my GMAT exam via phone?</h3>
<p><strong>Ans.</strong> Cancelling your test incurs an additional USD 10 fee. This fee is added to your cancellation charge. For example, cancelling 60 days before your test by phone would result in a USD 140 refund (USD 100 cancellation charge + USD 10 fee).</p>
<h3>3. Is the cost of attempting the GMAT online or from the test centre different?</h3>
<p><strong>Ans.</strong> Yes. The GMAT online is slightly more expensive than the test centre version. The online exam costs INR 25,190 (USD 300), while the test centre version costs INR 23,091 (USD 275).</p>
<h3>4. What is the total cost of registering for the GMAT exam?</h3>
<p><strong>Ans.</strong> The standard GMAT fee is USD 275 for the test centre and USD 300 for the online test.</p>
`;
